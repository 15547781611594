<template>
  <div class="anticheat">
    <p class="title">{{ $t('matches.anticheat_title') }}</p>
    <button class="launch" @click="handleLaunchAnchorClick">
      {{ $t('matches.anticheat_launch') }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    anticheat: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleLaunchAnchorClick() {
      window?.__GEM?.detectAndDownload('0.2000190');
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
}

.anticheat {
  padding: 20px;
  background: rgba(black, 0.21);
}

.launch {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-radius: $border-radius;
  border: none;
  font-weight: 600;
  font-size: 14px;
  min-width: 150px;
  margin: 12px auto;
  padding: 4px 12px;
  background-color: $azure;
  color: white;

  &,
  &:hover {
    text-decoration: none;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $water-blue;
    }
  }

  &:active {
    background-color: $nice-blue;
  }
}

.download {
  display: inline-block;
  margin-top: 4px;
  font-size: 14px;
}
</style>
