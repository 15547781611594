<template>
  <div class="manual-create-game-lobby">
    <div class="manual-create-game-lobby__header">
      <slot name="header"></slot>
    </div>
    <div class="manual-create-game-lobby__body">
      <slot name="body"></slot>
    </div>
    <div class="manual-create-game-lobby__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HubManualCreateGameLobby',
};
</script>

<style lang="scss" scoped>
</style>
