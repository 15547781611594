<template>
  <div v-if="statistics.isExist" class="match-statistic">
    <div class="statistic-title">{{ $t('games.statistic') }}</div>
    <div
      v-for="(stat, index) in statistics.games"
      :key="index"
      class="statistic-team"
    >
      <BaseTeamLink
        :hash="stat.hash"
        :display-image="false"
        class="statistic-team-name"
      />
      <div
        v-for="(table, key) in stat.games"
        :key="key"
        class="statistic-map-container"
      >
        <statistic-table
          :table="table"
          :players="stat.players"
        ></statistic-table>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticTable from '@components/Match/Common/StatisticTable.vue';
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';

export default {
  name: 'MatchStatisticTables',
  components: {
    StatisticTable,
    BaseTeamLink,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    ...mapGetters('matches', ['getMatch']),
    statistics() {
      const { statistics } = this.getMatch(this.id);
      return {
        isExist:
          statistics.games !== undefined && statistics.games.length,
        ...statistics,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.match-statistic {
  margin-top: 50px;
}

.statistic-title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.statistic-team {
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  @include max-laptop() {
    margin-bottom: 20px;
  }
}

.statistic-team-name {
  margin-bottom: 20px;
  font-size: 18px;
}

.statistic-map-container {
  margin-bottom: 1px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
