<template>
  <div class="start">
    <p class="title mb-16">
      {{ $t('matches.server_start_title') }}: {{ server.host }}
    </p>
    <a target="_blank" class="launch" :href="server.url">{{
      btnTitle
    }}</a>
    <pvp-tooltip v-if="isCsGame">
      <icon class="icon-helper" name="question-circle" inline />
      <template slot="tooltip">{{
        $t('matches.server_start_tooltip')
      }}</template>
    </pvp-tooltip>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    server: {
      type: Object,
      required: true,
    },
    isCsGame: {
      type: Boolean,
      default: false,
    },
    gameCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    btnTitle() {
      switch (this.gameCode) {
        case 'csg':
          return this.$t('matches.server_start_button_csgo');
        case 'cs2':
          return this.$t('matches.server_start_button_cs2');
        default:
          return this.$t('matches.server_start_button_default');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.start {
  margin-top: 1px;
  padding: 20px;
  background: rgba(black, 0.21);
}

.launch {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-radius: $border-radius;
  cursor: pointer;
  font-weight: 400;
  min-width: 150px;
  margin: 0 auto;
  padding: 4px 12px;
  background-color: $azure;
  color: white;

  &,
  &:hover {
    text-decoration: none;
    color: inherit;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $water-blue;
    }
  }

  &:active {
    background-color: $nice-blue;
  }
}

.icon-helper {
  margin-left: 6px;
  opacity: 0.4;
}
.mb-16 {
  margin-bottom: 16px;
}
</style>
