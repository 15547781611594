<template>
  <div>
    <div class="statistic-map" @click="toggleTable">
      <div class="statistic-map-title">
        {{ $t('matches.wf_map_single') }}: {{ table.map }}
      </div>
      <icon
        v-if="!activeTable"
        name="arrow-down"
        class="arrow-icon"
      />
      <icon v-else name="arrow-up" class="arrow-icon" />
    </div>
    <div v-if="activeTable" class="statistic-table__main">
      <table class="stripped statistic-table">
        <thead class="table-header">
          <tr class="table-row">
            <td class="table-column">{{ $t('players.single') }}</td>
            <td
              v-for="(col, index) in table.header"
              :key="index"
              class="table-column stats"
            >
              <pvp-tooltip>
                {{ col.name }}
                <template slot="tooltip">{{ col.fullName }}</template>
              </pvp-tooltip>
            </td>
          </tr>
        </thead>

        <tbody class="table-body">
          <tr
            v-for="(user, index) in players"
            :key="index"
            class="table-row"
          >
            <template>
              <template v-if="!!table.stats[user.hash]">
                <td class="table-column">
                  <div class="user">
                    <BaseUserLink
                      :hash="user.hash"
                      :class="{ captain: user.isCaptain }"
                    />
                    <div v-if="user.isCaptain" class="user-role">
                      {{ $t('players.role_captain') }}
                    </div>
                  </div>
                </td>
                <td
                  v-for="(col, key) in table.header"
                  :key="key"
                  class="table-column"
                >
                  <div class="stats">
                    {{ table.stats[user.hash][col.code] }}
                    <template v-if="col.code === 'accuracy'"
                      >%</template
                    >
                  </div>
                </td>
              </template>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import BaseUserLink from '@components/BaseComponents/BaseUserLink.vue';

export default {
  name: 'StatisticTable',
  components: {
    Icon,
    BaseUserLink,
  },
  props: {
    table: {
      type: Object,
      required: true,
    },
    players: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    activeTable: false,
  }),

  methods: {
    toggleTable() {
      this.activeTable = !this.activeTable;
    },
  },
};
</script>

<style lang="scss" scoped>
.statistic-map {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background-color: $slate;
  box-shadow: 0 2px 12px 0 rgba(#000, 0.11);
  cursor: pointer;
}

.statistic-map-title,
.arrow-icon {
  font-size: 15px;
  opacity: 0.7;
}

.statistic-table__main {
  width: 100%;
  overflow: auto;
}

.statistic-table {
  width: 100%;
  .table-column {
    &.stats {
      cursor: default;
    }

    @include max-laptop() {
      padding: 0 12px;
    }

    ::v-deep {
      .user-name {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include tablet-only() {
          max-width: 150px;
        }
      }
    }
  }

  .table-header {
    background-color: $dark-background;
    border-radius: 0;
    box-shadow: $default-box-shadow;
    position: relative;
    font-size: 16px;

    .table-column {
      padding-top: 10px;
      padding-bottom: 10px;
      color: rgba(white, 0.42);

      &:first-child {
        border-left: 1px solid;
      }

      &:last-child {
        border-right: 1px solid;
      }
    }

    .table-column,
    .table-column:first-child,
    .table-column:last-child {
      border-color: rgba(white, 0.1);
    }
  }
}

.user {
  @include max-tablet() {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.list-item {
  padding-bottom: 12px;
}

.user-link {
  &.captain {
    align-items: flex-start;
  }
}

thead {
  border: 1px solid rgba(white, 0.1);
}

tr:nth-child(odd) {
  background-color: $dark-background;
}

td {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 10px;
  padding-right: 10px;
}

.user-role {
  margin: -20px 0 0 46px;
  font-size: 14px;
  opacity: 0.4;
}

.key {
  margin-right: 6px;
  color: rgba(white, 0.6);
}
</style>
